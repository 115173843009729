<template>
  <div class="decorationBox">
    <div class="top">
      <el-button type="primary" size="small" @click="toAdd">导入商品</el-button>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品信息：">
        <el-input size="small" v-model="keyword" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="liveStatus" size="small" placeholder="请选择">
          <el-option
            v-for="item in liveStatusArr"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="searchFn">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :cell-style="() => 'text-align:center'"
      :data="allStatusGoods"
      :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }"
    >
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="wx_goods_id" label="商品ID" width="100"></el-table-column>
      <el-table-column label="商品图片" width="120">
        <template v-slot="{ row }">
          <div class="liveGoodsInfo">
            <img :src="row.cover_img" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="商品名称"></el-table-column>
      <el-table-column label="直播价格">
        <template v-slot="{ row }">
          <div v-if="!row.isUpdatePrice">{{ row.price }}元</div>
          <div v-else>
            <el-input
              id="updatePrice"
              style="width: 100px"
              size="mini"
              v-model="row.updatePrice"
            ></el-input>
            元
          </div>
        </template>
      </el-table-column>
      <el-table-column label="审核状态">
        <template v-slot="{ row }">
          {{
            row.status === 0
              ? '未审核'
              : row.status === 1
              ? '审核中 '
              : row.status === 2
              ? '审核通过'
              : row.status === 3
              ? '审核驳回'
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button
            v-if="row.status == 0 || row.status == 3"
            type="text"
            @click="replyExamine(row)"
            >重新审核</el-button
          >
          <el-button v-if="row.status == 1" type="text" @click="resetAudit(row)"
            >撤销审核</el-button
          >
          <!-- &&  -->
          <el-button
            v-if="row.status === 2 && !row.isUpdatePrice"
            type="text"
            @click.stop="updatePriceFn(row)"
            >修改价格</el-button
          >
          <el-button
            v-if="row.status === 2 && row.isUpdatePrice"
            type="text"
            @click.stop="updatePriceSuccess(row)"
            >完成修改</el-button
          >
          <el-button v-if="row.status !== 1" type="text" @click="deleteGoods(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNums" @updatePageNum="updateData"></Paging>
    <!-- 导入商品 -->
    <el-dialog title="选择商品" :visible.sync="chooseGoddsFlag" width="1200px">
      <div class="chooseGoodsBox">
        <el-form class="el-form-search" label-width="100px">
          <el-form-item label="商品信息：">
            <el-input size="small" v-model="keywordLog" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="商品分类：">
            <el-select v-model="chooseClass" size="small" placeholder="请选择">
              <el-option
                v-for="item in goodsClassList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" " label-width="20px">
            <el-button type="primary" size="small" @click="searchFnLog">搜索</el-button>
          </el-form-item>
        </el-form>
        <div class="bot">
          <div class="showList">
            <div class="listHead itemRow">
              <div>商品信息</div>
              <div>单价</div>
              <div>直播商品图</div>
              <div>直播商品价格</div>
              <div>入库状态</div>
            </div>
            <div v-for="(item, index) in storeAllGoods" :key="index">
              <div class="listItem itemRow" @click="item.is_showSpec = !item.is_showSpec">
                <div class="goodsInfo">
                  <i
                    :class="['el-icon-caret-right', item.is_showSpec ? 'ro90' : '']"
                    v-if="item.is_spec"
                  ></i>
                  <img
                    :style="{ margin: item.is_spec ? ' 0 10px' : '0 10px 0 34px' }"
                    :src="item.goods_picture"
                    alt=""
                  />
                  <div>
                    <p>{{ item.goods_name }}</p>
                    <p v-if="!item.is_spec">
                      库存：{{ item.goods_stock != -1 ? item.goods_stock : '无限库存' }}
                    </p>
                  </div>
                </div>
                <div class="price">{{ item.goods_price }}</div>
                <div class="liveImg" v-if="item.is_spec"></div>
                <div class="liveImg" v-else-if="item.is_in">
                  <img :src="item.goods_cover_img" alt="" />
                </div>
                <div class="liveImg" v-else>
                  <ReadyUploadSource
                    :path="item.cover_img"
                    :showStyle="{ width: '60px', height: '60px' }"
                    type="live"
                    :url="2"
                    :liveStatus="1"
                    @getSource="(val) => getSource(item, val)"
                    @removeThis="() => removeThis(item)"
                    :maxSize="1024 * 1024"
                  ></ReadyUploadSource>
                </div>
                <div class="livePrice" v-if="item.is_spec"></div>
                <div class="livePrice" v-else-if="item.is_in">{{ item.live_price }}</div>
                <div class="livePrice" v-else>
                  <el-input v-model="item.live_price" size="small"></el-input>
                  元
                </div>
                <div class="status" v-if="item.is_spec"></div>
                <div
                  class="status cupo"
                  @click="surePushLive(item, 1)"
                  :style="{ color: item.is_in ? '#333' : '#409eff' }"
                  v-else
                >
                  {{ item.is_in ? '已参与' : '参与直播' }}
                </div>
              </div>
              <div
                class="manySize"
                :style="{ height: item.is_showSpec ? item.goods_spec.length * 70 + 'px' : '0px' }"
                v-if="item.is_spec"
              >
                <div class="itemRow" v-for="(item1, index1) in item.goods_spec" :key="index1">
                  <div class="goodsInfo">
                    <img :src="item1.picture" alt="" />
                    <div>
                      <p>{{ item1.title }}</p>
                      <p>库存：{{ item1.stock != -1 ? item1.stock : '无限库存' }}</p>
                    </div>
                  </div>
                  <div class="price">{{ item1.price }}</div>
                  <div class="liveImg" v-if="item1.is_in">
                    <img :src="item1.goods_cover_img" alt="" />
                  </div>
                  <div class="liveImg" v-else>
                    <ReadyUploadSource
                      :path="item1.cover_img"
                      :showStyle="{ width: '60px', height: '60px' }"
                      type="live"
                      :url="2"
                      @getSource="(val) => getSource(item1, val)"
                      @removeThis="() => removeThis(item1)"
                      :liveStatus="1"
                    ></ReadyUploadSource>
                  </div>
                  <div class="livePrice" v-if="item1.is_in">{{ item1.live_price }}</div>
                  <div class="livePrice" v-else>
                    <el-input v-model="item1.live_price" size="small"></el-input>
                    元
                  </div>
                  <div
                    class="status cupo"
                    @click="surePushLive(item1, 2, item)"
                    :style="{ color: item1.is_in ? '#333' : '#409eff' }"
                  >
                    {{ item1.is_in ? '已参与' : '参与直播' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Paging
            :total="totalLog"
            :page="pageLog"
            :pageNum="pageNumsLog"
            @updatePageNum="updateDataLog"
          ></Paging>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="chooseGoddsFlag = false">取 消</el-button>
        <el-button type="primary" @click="surePushLive">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import Paging from '@/components/paging'
import ReadyUploadSource from '@/components/readyUploadSource'
export default {
  components: {
    Paging,
    ReadyUploadSource
  },
  data() {
    return {
      page: 1,
      pageNums: 10,
      total: 0,
      allStatusGoods: [],
      keyword: '',
      liveStatus: -1,
      liveStatusArr: [
        {
          value: -1,
          name: '全部'
        },
        {
          value: 0,
          name: '未审核'
        },
        {
          value: 1,
          name: '审核中'
        },
        {
          value: 2,
          name: '审核通过'
        },
        {
          value: 3,
          name: '审核驳回'
        }
      ],
      chooseGoddsFlag: false,
      storeAllGoods: [],
      keywordLog: '',
      pageLog: 1,
      pageNumsLog: 10,
      totalLog: 0,
      chooseClass: 0,
      goodsClassList: [],
      // 此时被修改的价格的那一项
      nowUpdateItem: null
    }
  },
  mounted() {
    document.addEventListener('click', this.documentCFn)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentCFn)
  },
  methods: {
    async toAdd() {
      let a = await this.getStoreAllClass()
      let b = await this.getStoreAllGoods()
      this.chooseGoddsFlag = true
    },
    documentCFn($evevt) {
      if ($evevt.target.id !== 'updatePrice' && this.nowUpdateItem) {
        this.updatePriceSuccess(this.nowUpdateItem)
      }
    },
    // 获取已参与的商品列表
    getLiveList() {
      let that = this
      this.$axios
        .post(this.$api.push.liveRoom.goodsList, {
          page: this.page,
          row: this.pageNums,
          status: this.liveStatus == -1 ? null : this.liveStatus
        })
        .then((res) => {
          if (res.code == 0) {
            that.allStatusGoods = res.result.list
            that.allStatusGoods.map((item) => {
              that.$set(item, 'updatePrice', item.price)
              that.$set(item, 'isUpdatePrice', false)
            })
            that.total = res.result.total
          }
        })
    },
    // 修改分页数据
    updateData(val, status) {
      if (status == 0) {
        this.pageNums = val
      } else {
        this.page = val
      }
      // 重新获取数据
      this.getLiveList()
    },
    updateDataLog(val, status) {
      if (status == 0) {
        this.pageNumsLog = val
      } else {
        this.pageLog = val
      }
      // 重新获取数据
      this.getStoreAllGoods()
    },
    // 点击进行搜索
    searchFn() {
      this.page = 1
      this.getLiveList()
    },
    // 清空搜索
    clearSearch() {
      this.page = 1
      this.keyword = ''
      this.liveStatus = -1
      this.getLiveList()
    },
    // 获取站点所有商品列表
    getStoreAllGoods() {
      let that = this
      this.$axios
        .post(this.$api.push.liveRoom.getGoodList, {
          page: this.pageLog,
          rows: this.pageNumsLog,
          goods_name: this.keywordLog,
          classify_id: !this.chooseClass ? null : this.chooseClass,
          is_putaway: 2,
          status: 1
        })
        .then((res) => {
          if (res.code === 0) {
            that.totalLog = res.result.total_number
            that.storeAllGoods = res.result.list
            that.storeAllGoods.map((item) => {
              that.$set(item, 'is_showSpec', true)
              if (!item.is_in) {
                item.live_price = item.goods_price
              }
              if (!item.is_spec) {
                that.$set(item, 'coverImgUrl', '')
                that.$set(item, 'cover_img', '')
              } else {
                item.goods_spec.map((item1) => {
                  if (!item1.is_in) {
                    item1.live_price = item1.price
                  }
                  that.$set(item1, 'coverImgUrl', '')
                  that.$set(item1, 'cover_img', '')
                })
              }
            })
          }
        })
    },
    // 获取站点商品分类列表
    getStoreAllClass() {
      let that = this
      this.$axios.post(this.$api.goods.goodsClass).then((res) => {
        if (res.code === 0) {
          that.goodsClassList = res.result
          that.goodsClassList.unshift({ id: 0, name: '全部' })
        }
      })
    },
    searchFnLog() {
      this.pageLog = 1
      this.getStoreAllGoods()
    },
    // 确认将选中的商品推送到直播商品库
    surePushLive(row, status, pItem) {
      if (row.is_in) return
      if (!row.cover_img) {
        this.$message({
          message: `请先上传直播商品图`,
          type: 'warning',
          customClass: 'mzindex'
        })
        return
      }
      let str = null
      if (status == 1) {
        str = row.goods_name.replace(/[^\u0000-\u00ff]/g, 'aa').length
      } else {
        str = pItem.goods_name.replace(/[^\u0000-\u00ff]/g, 'aa').length
      }
      if (str > 28) {
        this.$message({
          message: `微信限制直播商品名称不可超过14个汉字(28字节)`,
          type: 'warning',
          customClass: 'mzindex'
        })
        return
      }
      let that = this
      let obj = {
        id: row.id,
        coverImgUrl: row.coverImgUrl,
        cover_img: row.cover_img,
        price: row.live_price
      }
      if (status == 2) {
        obj.id = pItem.id
        obj.sku_id = row.id
      }
      this.$axios.post(this.$api.push.liveRoom.addAllGoods, obj).then((res) => {
        if (res.code == 0) {
          that.$message.success('添加成功')
          that.getLiveList()
          that.chooseGoddsFlag = false
        } else {
          that.$message.error(res.msg)
        }
      })
    },
    // 获取到上传的直播时的商品图
    getSource(item, val) {
      this.$set(item, 'coverImgUrl', val.media_id)
      this.$set(item, 'cover_img', val.path)
    },
    removeThis(item) {
      this.$set(item, 'coverImgUrl', '')
      this.$set(item, 'cover_img', '')
    },
    // 重新提审
    replyExamine(row) {
      let that = this
      this.$axios
        .post(this.$api.push.liveRoom.resubmitAudit, {
          goodsId: row.wx_goods_id
        })
        .then((res) => {
          if (res.code === 0) {
            that.$message.success('成功提交审核')
            row.status = 1
          } else {
            that.$message.error(res.msg)
          }
        })
    },
    // 撤销审核
    resetAudit(row) {
      let that = this
      this.$axios
        .post(this.$api.push.liveRoom.resetAudit, {
          auditId: row.audit_id,
          goodsId: row.wx_goods_id
        })
        .then((res) => {
          if (res.code === 0) {
            that.$message.success('撤销成功')
            row.status = 0
          } else {
            that.$message.error(res.msg)
          }
        })
    },
    // 点击修改价格
    updatePriceFn(row) {
      this.nowUpdateItem = row
      row.isUpdatePrice = true
    },
    // 成功修改价格
    updatePriceSuccess(row) {
      let that = this
      let goodsInfo = {}
      goodsInfo.goodsId = row.wx_goods_id
      goodsInfo.price = row.updatePrice
      goodsInfo.coverImgUrl = row.coverImgUrl
      goodsInfo.name = row.name
      goodsInfo.priceType = row.priceType
      goodsInfo.url = row.url
      this.$axios.post(this.$api.push.liveRoom.updateGoods, goodsInfo).then((res) => {
        if (res.code === 0) {
          row.isUpdatePrice = false
          that.nowUpdateItem = null
          row.price = row.updatePrice
          that.$message.success('修改成功')
        } else {
          that.$message.error(res.msg)
        }
      })
    },
    // 删除该商品
    deleteGoods(row) {
      this.$confirm('此操作将该商品从商品库中删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return this.$axios.post(this.$api.push.liveRoom.deleteGoods, {
            goodsId: row.wx_goods_id
          })
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.getLiveList()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.decorationBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    margin-bottom: 10px;
  }
  .el-table {
    height: 100%;
    /deep/ .el-table__body-wrapper {
      height: calc(100% - 48px);
      overflow-y: auto;
    }
  }
  .liveGoodsInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .chooseGoodsBox {
    .bot {
      .showList {
        width: 100%;
        .listHead {
          width: 100%;
          display: flex;
          line-height: 48px;
          background-color: #f8f9fa;
        }
        .itemRow {
          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          & > div:first-of-type {
            width: 300px;
            flex-shrink: 0;
            padding: 0 10px;
            i {
              font-size: 24px;
              transition: all 0.3s;
            }
            .ro90 {
              transform: rotate(90deg);
            }
            img {
              width: 40px;
              height: 40px;
            }
            & > div {
              height: 100%;
              flex: 1;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 14px 0;
              p {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          & > div:nth-of-type(2) {
            width: 100px;
            flex-shrink: 0;
          }
          & > div:nth-of-type(3) {
            img {
              width: 60px;
              height: 60px;
            }
            font-size: 12px;
            width: 300px;
            flex-shrink: 0;
          }
          & > div:nth-of-type(4) {
            width: 300px;
            flex-shrink: 0;
            .el-input {
              width: 100px;
            }
          }
          & > div:nth-of-type(5) {
            flex: 1;
            flex-shrink: 0;
          }
        }
        .cupo {
          cursor: pointer;
        }
        .listItem {
          display: flex;
          height: 70px;
          cursor: pointer;
          &:hover {
            background-color: #f5f7fa;
          }
        }
        .manySize {
          transition: all 0.3s;
          overflow: hidden;
          .itemRow {
            display: flex;
            height: 70px;
            &:hover {
              background-color: #f5f7fa;
            }
          }
          .goodsInfo {
            padding: 0 10px 0 75px !important;
            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .priceBox {
    display: flex;
    align-items: center;
  }
}
</style>
