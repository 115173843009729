<template>
  <el-container>
    <el-main>
      <div class="smallBox">
        <SmallCard :info="smallInfoLive" @clickFn="toAdd"></SmallCard>
        <SmallCard :info="smallInfoGoods" @clickFn="addGoods"></SmallCard>
      </div>
      <div class="decorationBox">
        <el-tabs v-model="activeName" @tab-click="handClick">
          <el-tab-pane label="直播间" name="0">
            <LiveList ref="LiveList"></LiveList>
          </el-tab-pane>
          <el-tab-pane label="商品库" name="1">
            <GoodsDB ref="GoodsDB"></GoodsDB>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import LiveList from './components/liveList.vue'
import GoodsDB from './components/goodsBase.vue'
import SmallCard from '@/components/smallCardBox.vue'
export default {
  components: {
    LiveList,
    GoodsDB,
    SmallCard
  },
  data() {
    return {
      activeName: '0',
      smallInfoLive: {
        img: require('@/assets/smallCardImgs/1.png'),
        title: '直播',
        desc: '直播种草，高效复购',
        btnTxt: '创建直播间'
      },
      smallInfoGoods: {
        img: require('@/assets/smallCardImgs/2.png'),
        title: '导入直播商品',
        desc: '衔接直播商品库',
        btnTxt: '导入商品'
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.LiveList.getLiveList()
    })
  },
  methods: {
    addGoods() {
      this.activeName = '1'
      this.$nextTick(() => {
        this.$refs.GoodsDB.getLiveList()
        this.$refs.GoodsDB.toAdd()
      })
    },
    toAdd() {
      this.$router.push('/extension/addAppletLiveBroadcast')
    },
    handClick() {
      if (this.activeName == '0') {
        this.$nextTick(() => {
          this.$refs.LiveList.getLiveList()
        })
      } else if (this.activeName == '1') {
        this.$nextTick(() => {
          this.$refs.GoodsDB.getLiveList()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  overflow: auto;
  background-color: #fff;
  .smallBox {
    display: flex;
    align-items: center;
  }
  .decorationBox {
    width: 100%;
  }
}
</style>
